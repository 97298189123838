import { createContext, useEffect, useState } from "react";

const ThemeContext = createContext<{
  themeName: "light" | "dark";
  toggleTheme: () => void;
}>({
  themeName: "dark",
  toggleTheme: () =>{ console.log('### TOGGLE THEME DEFAULT'); localStorage.setItem("themeName", "dark")},
});

const ThemeProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [themeName, setThemeName] = useState<"light" | "dark">("dark");

  useEffect(() => {
    const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setThemeName(darkMediaQuery.matches ? "dark" : "light");
    darkMediaQuery.addEventListener("change", (e) => {
      setThemeName(e.matches ? "dark" : "light");
    });
  }, []);

  const toggleTheme = () => {
    console.log("### toggle theme", themeName);
    const name = themeName === "dark" ? "light" : "dark";
    localStorage.setItem("themeName", name);
    setThemeName(name);
  };

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
