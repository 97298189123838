import "./Footer.css";

const Footer = () => (
  <footer className="footer">
    <a
      href="https://datacvr.virk.dk/enhed/virksomhed/43289373"
      className="link footer__link"
    >
      Rasck Solutions ApS - DK43289373 - rasck@rasck.solutions - Admiraltoften
      11, 8660 Skanderborg
    </a>
  </footer>
);

export default Footer;
