const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: "https://rajshekhar26.github.io/cleanfolio",
  title: "TS.",
};

const about = {
  // all the properties are optional - can be left empty or deleted
  name: "Rasmus Knap",
  role: "full stack engineer with focus on front-end work",
  description: "Specialized in React & React Native",
  //resume: "https://example.com",
  social: {
    linkedin: "https://www.linkedin.com/in/rasck/",
    github: "https://github.com/rasck",
  },
};

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: "Grundfos",
    description:
      "Developing a front-end for a dosing pump solution. Responsible for webportal and nodejs graphql backend",
    stack: ["TypeScript", "React", "Graphql", "nodejs", "Relay"],
  },
  {
    name: "NTI A/S",
    description:
      "Developing a mobile app from scratch. Setup app in Android and iOS stores, CI/CD, automated testing",
    stack: ["React Native", "Expo EAS", "TypeScript", "Azure devops"],
  },
  {
    name: "Debitoor",
    description:
      "Worked as senior developer on an existing React Native app. Major work included performance improvement and make the app support different tax schemes",
    stack: ["React Native", "JavaScript", "Detox", "Redux"],
  },
];

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  "HTML",
  "CSS",
  "JavaScript",
  "TypeScript",
  "React",
  "React Native",
  "C#",
  "nodejs",
  "sql",
  "Redux",
  "Git",
  "CI/CD",
  "Jest",
];

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: "rasck@rasck.solutions",
};

export interface Project {
  name: string;
  description: string;
  stack: string[];
  sourceCode?: string;
  livePreview?: string;
}

export { header, about, projects, skills, contact };
